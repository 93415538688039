<template>
	<div class="delivery-order">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<div class="mb12" style="display: flex;width: 100%;align-items: center;">
				<div style="width: 94px;">订单状态：</div>
				<a-radio-group v-model:value="search.status" button-style="solid" @change="getDeliveryList(1,info.limit)">
					<a-radio-button value="0">全部</a-radio-button>
					<a-radio-button value="1">待配送</a-radio-button>
					<a-radio-button value="2">已发货</a-radio-button>
					<a-radio-button value="3">已收货</a-radio-button>
					<a-radio-button value="4">已支付</a-radio-button>
					<a-radio-button value="5">未支付</a-radio-button>
					<a-radio-button value="6">已退款</a-radio-button>
					<a-radio-button value="7">已取消</a-radio-button>
				</a-radio-group>
			</div>
			<div class="mb12" style="display: flex;width: 100%;align-items: center;">
				<div style="width: 100px;">订单搜索：</div>
				 <a-input-group compact>
					<a-select v-model:value="search.type" style="width: 150px;">
						<a-select-option :value="1">配送订单编号</a-select-option>
						<a-select-option :value="2">收货人电话</a-select-option>
						<a-select-option :value="3">收货人姓名</a-select-option>
						<a-select-option :value="4">快递公司</a-select-option>
						<a-select-option :value="5">快递单号</a-select-option>
					</a-select>
					<a-input v-if="search.type == 1" style="width: 250px" v-model:value="search.order_number" />
					<a-input v-if="search.type == 2" style="width: 250px" v-model:value="search.phone" />
					<a-input v-if="search.type == 3" style="width: 250px" v-model:value="search.name" />
					<a-input v-if="search.type == 4" style="width: 250px" v-model:value="search.express_company" />
					<a-input v-if="search.type == 5" style="width: 250px" v-model:value="search.express_no" />
				</a-input-group>
			</div>
			<div class="mb12" style="display: flex;width: 100%;align-items: center;">
				<div style="width: 94px;">收货地址：</div>
				<a-input style="width: 400px" v-model:value="search.address" />
			</div>
			<a-space style="margin-left: 94px;">
				<a-button type="primary" @click="getDeliveryList(1,info.limit)"><i class="ri-search-2-line ri-top"></i>搜索</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<div v-if="info.list.length > 0">
				<com-delivery-list :list="info.list"  @success="()=>getDeliveryList(info.page,info.limit)"></com-delivery-list>
			</div>
			<div class="pager">
				<a-pagination 
				show-size-changer 
				:default-current="info.page" 
				:total="info.count" 
				@showSizeChange="(page,e)=>{ getDeliveryList(info.page, e); }"
				@change="e=>{getDeliveryList(e, info.limit);}"/>
			</div>
		</div>
	</div>
</template>

<script>
import landModel from '@/api/land.js'
import comPrintFaceOrder from '@/components/miniapp/com-print-face-order.vue'
import comDeliveryList from '@/components/layout/land/com-delivery-list.vue'
import { onActivated, reactive, toRefs } from 'vue'
export default{
	name:'com-delivery-order',
	components:{
		comPrintFaceOrder,
		comDeliveryList
	},
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			search:{
				type:1,
				status:'0',
				order_number:'',
				name:'',
				phone:"",
				address:"",
				express_no:"",
				express_company:"",
			},
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
		})

		onActivated(()=>{
			getDeliveryList(_d.info.page,_d.info.limit)
		})

		getDeliveryList(1,_d.info.limit)
		function getDeliveryList(page,limit){
			landModel.getDeliveryOrder(page,limit,_d.search,res=>{
				_d.info = { limit,...res}
			})
		}
		return {
			...toRefs(_d),
			getDeliveryList
		}
	}
}
</script>
<style>
</style>
